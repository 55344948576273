/* Wrapper to center the navbar */
.Slider_home_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  /* width: 100%; */
  padding: 20px;
  /* padding-top: 50px; */
  background: #ffffff; 
  /* margin-top: 50px; */
}


/* main app container  */
.Slider_home_container {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  /* width: 100%; */
  padding: 20px;
}

/* Contain Text "Welcome to" + PowerOptimus Logo + Carousel  */
.launch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-top: 50px;
}

/* Parnet : launch-container for "welcome text" + "PowerOptimus" */
.text-container {
  text-align: center;
}

/* Title : Welcome to */
.welcomeTo {
  font-size: 2rem;
  font-family: 'Roboto Condensed', sans-serif;
  color: #1d1d1d;
  font-weight: 600;
}

/***************** Media Queries :: Title welcome to *******************************************/
/* Mobile devices (portrait) */
@media (max-width: 480px) {
  .welcomeTo {
    font-size: 1.7rem;
    font-weight: 600;
  }
}

/* Mobile devices (landscape) */
@media (min-width: 481px) and (max-width: 767px) {
  .welcomeTo {
    font-size: 1.5rem;
    font-weight: 600;
  }
}

/* Tablets (portrait) */
@media (min-width: 768px) and (max-width: 1023px) {
  .welcomeTo {
    font-size: 1.7rem;
    font-weight: 600;
  }
}

/* Tablets (landscape) */
@media (min-width: 1024px) and (max-width: 1279px) {
  .welcomeTo {
    font-size: 1.7rem;
    font-weight: 600;
  }
}

/* Desktop computers */
@media (min-width: 1280px) {
  .welcomeTo {
    font-size: 2rem;
    font-weight: 600;
  }
}

/*****************************************************************************************/
                                    /* Title :: welcome to */
/*****************************************************************************************/

/* PowerOptimus LOGO  */
.app-icon2 {
  width: 100%;
  max-width: 400px;
  height: auto;
  /* margin-bottom: 10px; */
}

/* Carousel styles */
.carousel {
  position: relative;
  width: 100%;
  height: 55vh;
  overflow: hidden;
}

/* Carousel Images */
.carousel-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  transition: transform 0.8s ease, opacity 0.8s ease;
  z-index: 1;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

/* Current (center) image */
.carousel-item.current {
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  z-index: 3;
}

/* Previous and Next images */
.carousel-item.previous,
.carousel-item.next {
  transform: translate(-50%, -50%) scale(0.8);
  /* opacity: 0.7; */
  z-index: 2;
}

.carousel-item.previous {
  transform: translate(calc(-50% - 20%), -50%) scale(0.8);
}

.carousel-item.next {
  transform: translate(calc(-50% + 20%), -50%) scale(0.8);
}

/* Far Previous and Far Next images */
.carousel-item.far-previous,
.carousel-item.far-next {
  transform: translate(-50%, -50%) scale(0.6);
  /* opacity: 0.3; */
  z-index: 1;
}

.carousel-item.far-previous {
  transform: translate(calc(-50% - 40%), -50%) scale(0.6);
}

.carousel-item.far-next {
  transform: translate(calc(-50% + 40%), -50%) scale(0.6);
}

/* Footer styles */
footer {
  position: relative;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: 1rem;
  padding: 10px;
  margin-top: 20px;
}

/* SubTitle : Your Trusted Partner in Energy Efficiency Monitoring and Management */
.subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-top: 10px;
  font-family: 'Roboto Condensed', sans-serif;
}

/* SubTitle : A soltion by */
.subtitle2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #303030;
  margin-top: 10px;
  font-family: 'Roboto Condensed', sans-serif;
}

.no-blue-link {
  text-decoration: none;
  color: inherit;
  display: inline-flex;
  align-items: center;
}

.aSolutionBy {
  margin-right: 5px;
}

.neilsoft-logo-image {
  width: 90px;
  height: auto;
}

/* Responsive logo sizes */
@media (min-width: 768px) and (max-width: 1023px) {
  .neilsoft-logo-image {
    width: 110px;
  }
}

@media (max-width: 767px) {
  .neilsoft-logo-image {
    width: 90px;
  }
}

/************************************************** max-width : 1290px ************************************************/
/* Responsive carousel adjustments */
@media (max-width: 1290px) {
  /* Contain Text "Welcome to" + PowerOptimus Logo + Carousel  */
  .launch-container {
    width: 100%;
  }
  
  
  /* Carousel styles */
  .carousel {
    width: 75%;
    height: 60vh;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  
  /* Carousel Images */
  .carousel-item {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    transition: transform 0.8s ease, opacity 0.8s ease;
    z-index: 1;
  }
  
  .carousel-item img {
    width: 100%;
    height: auto;
  }
  
  /* Current (center) image */
  .carousel-item.current {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
    z-index: 3;
  }
  
  .carousel-item.previous {
    transform: translate(calc(-50% - 12%), -50%) scale(0.7);
  }
  
  .carousel-item.next {
    transform: translate(calc(-50% + 12%), -50%) scale(0.7);
  }
  
  
  .carousel-item.far-previous {
    transform: translate(calc(-50% - 25%), -50%) scale(0.5);
  }
  
  .carousel-item.far-next {
    transform: translate(calc(-50% + 25%), -50%) scale(0.5);
  }
  
  .subtitle {
    font-size: 1.4rem;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: 'Roboto Condensed', sans-serif;
  }
  }


/************************************************** max-width : 1024px ************************************************/
/* Responsive carousel adjustments */
@media (max-width: 1024px) {
/* Contain Text "Welcome to" + PowerOptimus Logo + Carousel  */
.launch-container {
  width: 100%;
}


/* Carousel styles */
.carousel {
  width: 100%;
  height: 50vh;
  margin-top: 10px;
  margin-bottom: 00px;
}

/* Carousel Images */
.carousel-item {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  transition: transform 0.8s ease, opacity 0.8s ease;
  z-index: 1;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

/* Current (center) image */
.carousel-item.current {
  transform: translate(-50%, -50%) scale(0.8);
  opacity: 1;
  z-index: 3;
}

.carousel-item.previous {
  transform: translate(calc(-50% - 12%), -50%) scale(0.7);
}

.carousel-item.next {
  transform: translate(calc(-50% + 12%), -50%) scale(0.7);
}


.carousel-item.far-previous {
  transform: translate(calc(-50% - 25%), -50%) scale(0.5);
}

.carousel-item.far-next {
  transform: translate(calc(-50% + 25%), -50%) scale(0.5);
}

.subtitle {
  font-size: 1.4rem;
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: 'Roboto Condensed', sans-serif;
}
}

/************************************************** max-width : 930px ************************************************/
/* Responsive carousel adjustments */
@media (max-width: 930px) {
  /* Contain Text "Welcome to" + PowerOptimus Logo + Carousel  */
  .launch-container {
    width: 100%;
  }
  
  
  /* Carousel styles */
  .carousel {
    width: 100%;
    height: 40vh;
    margin-top: 10px;
    margin-bottom: 00px;
  }
  
  /* Carousel Images */
  .carousel-item {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    transition: transform 0.8s ease, opacity 0.8s ease;
    z-index: 1;
  }
  
  .carousel-item img {
    width: 100%;
    height: auto;
  }
  
  /* Current (center) image */
  .carousel-item.current {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
    z-index: 3;
  }
  
  .carousel-item.previous {
    transform: translate(calc(-50% - 12%), -50%) scale(0.7);
  }
  
  .carousel-item.next {
    transform: translate(calc(-50% + 12%), -50%) scale(0.7);
  }
  
  
  .carousel-item.far-previous {
    transform: translate(calc(-50% - 25%), -50%) scale(0.5);
  }
  
  .carousel-item.far-next {
    transform: translate(calc(-50% + 25%), -50%) scale(0.5);
  }
  
  .subtitle {
    font-size: 1.4rem;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: 'Roboto Condensed', sans-serif;
  }
  }
/************************************************** max-width : 768px ************************************************/
@media (max-width: 768px) {
/* Contain Text "Welcome to" + PowerOptimus Logo + Carousel  */
.launch-container {
  width: 100%;
}


/* Carousel styles */
.carousel {
  width: 100%;
  height: 45vh;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Carousel Images */
.carousel-item {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  transition: transform 0.8s ease, opacity 0.8s ease;
  z-index: 1;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

/* Current (center) image */
.carousel-item.current {
  transform: translate(-50%, -50%) scale(0.8);
  opacity: 1;
  z-index: 3;
}

.carousel-item.previous {
  transform: translate(calc(-50% - 12%), -50%) scale(0.7);
}

.carousel-item.next {
  transform: translate(calc(-50% + 12%), -50%) scale(0.7);
}


.carousel-item.far-previous {
  transform: translate(calc(-50% - 25%), -50%) scale(0.5);
}

.carousel-item.far-next {
  transform: translate(calc(-50% + 25%), -50%) scale(0.5);
}

.subtitle {
  font-size: 1.2rem;
  margin-top: 0px;
  font-family: 'Roboto Condensed', sans-serif;
}
}

/************************************************** max-width : 600px ************************************************/
@media (max-width: 600px) {
  /* Contain Text "Welcome to" + PowerOptimus Logo + Carousel  */
  .launch-container {
    width: 100%;
  }
  
  
  /* Carousel styles */
  .carousel {
    width: 100%;
    height: 40vh;
    margin-top: 10px;
  }
  
  /* Carousel Images */
  .carousel-item {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);
    transition: transform 0.8s ease, opacity 0.8s ease;
    z-index: 1;
  }
  
  .carousel-item img {
    width: 100%;
    height: auto;
  }
  
  /* Current (center) image */
  .carousel-item.current {
    transform: translate(-50%, -50%) scale(0.8);
    opacity: 1;
    z-index: 3;
  }
  
  .carousel-item.previous {
    transform: translate(calc(-50% - 12%), -50%) scale(0.7);
  }
  
  .carousel-item.next {
    transform: translate(calc(-50% + 12%), -50%) scale(0.7);
  }
  
  
  .carousel-item.far-previous {
    transform: translate(calc(-50% - 25%), -50%) scale(0.5);
  }
  
  .carousel-item.far-next {
    transform: translate(calc(-50% + 25%), -50%) scale(0.5);
  }
  
  .subtitle {
    font-size: 1.2rem;
    margin-top: 0px;
    line-height: 1.5;
    font-family: 'Roboto Condensed', sans-serif;
  }
  }
  
/************************************************** max-width :: 480px ************************************************/
@media (max-width: 480px) {

/* Contain Text "Welcome to" + PowerOptimus Logo + Carousel  */
.launch-container {
  width: 100%;
}


/* Carousel styles */
.carousel {
  width: 100%;
  height: 25vh;
  margin-top: 10px;
  margin-bottom: 0px;
}

/* Carousel Images */
.carousel-item {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.6);
  transition: transform 0.8s ease, opacity 0.8s ease;
  z-index: 1;
}

.carousel-item img {
  width: 100%;
  height: auto;
}

/* Current (center) image */
.carousel-item.current {
  transform: translate(-50%, -50%) scale(0.8);
  opacity: 1;
  z-index: 3;
}

.carousel-item.previous {
  transform: translate(calc(-50% - 12%), -50%) scale(0.7);
}

.carousel-item.next {
  transform: translate(calc(-50% + 12%), -50%) scale(0.7);
}


.carousel-item.far-previous {
  transform: translate(calc(-50% - 25%), -50%) scale(0.5);
}

.carousel-item.far-next {
  transform: translate(calc(-50% + 25%), -50%) scale(0.5);
}

.subtitle {
  font-size: 1rem;
  margin-top: 0px;
  line-height: 1.4;
  font-family: 'Roboto Condensed', sans-serif;
}

}
