/* Wrapper to center the navbar */
.HomeHowItWork_container {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(255, 255, 255);
}

.HomeHowItWork_Home {
  padding-top: 60px;
  padding-bottom: 1px;
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr; /* 14 grid columns */
  /* align-items: center; */
  row-gap: 1px;
  column-gap: 30px;
  max-width: 2300px;
  background: #ffffff; 
}

.HomeHowItWork_left{
  padding-top: 1px;
  grid-column-start: 3;
  grid-column-end: 14;
  grid-row-start: 1;
  grid-row-end: 2;
}

/* .HomeHowItWork_left{
  padding-top: 110px;
  grid-column-start: 2;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 2;
} */


.imagewidthhome2{
  width: 90%;
}

.HomeHowItWork_right{
  padding-top: 30px;
  grid-column-start: 3;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

/* .HomeHowItWork_right{
  padding-top: 130px;
  grid-column-start: 8;
  grid-column-end: 13;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
} */

.buttonDiv{
  margin-top: 15px;
}
.know-more-button2 {
  background-color: #1E9500; /* Fluent Design System blue */
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.know-more-button2:hover {
  background-color: #175d05; /* Darker shade of blue for hover */
}

.newTable{
 background-color: red;
 width:100%;
}
/* Media Queries for Responsive Design */
/* For very small devices like phones in portrait mode */
@media (max-width: 480px) {

  .HomeHowItWork_Home {
    padding-top: 10px;
    padding-bottom: 5px;
    display: grid;
    width: 100%;
    grid-template-columns: 10px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 10px; /* 14 grid columns */
    /* align-items: center; */
    row-gap: 10px;
    column-gap: 5px;
    max-width: 2300px;
    background: #ffffff; 
  }

  .HomeHowItWork_left{
    padding-top: 50px;
    grid-column-start: 2;
    grid-column-end: 15;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .HomeHowItWork_right{
    padding-top: 10px;
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 3;
    grid-row-end: 4;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  
.buttonDiv{
  margin-top: 5px;
}

}  