/* Wrapper to center the form */
.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(255, 255, 255);
}

.contact {
  /* margin-top: 90px; */
  padding-top: 120px;
  padding-bottom: 50px;
  display: grid;
  margin-bottom: 30px;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr; /* 14-column grid */
  align-items: start;
  row-gap: 20px;
  column-gap: 30px;
  max-width: 2300px;
  background: #ffffff;
}

/* Left Section */
.contact_sectionHeader_left {
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 15px;
}

/* Right Section */
.contact_sectionHeader_right {
  grid-column-start: 8;
  grid-column-end: 14;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
}

.contact-form input,
.contact-form textarea {
  width: auto;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form button {
  background: #1E9500;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  width: 150px;
}

.contact-form button:hover {
  background: #1a7303;
}

/* Responsive Design */
@media (max-width: 480px) {
  .contact {
    padding-top: 60px;
    padding-bottom: 20px;
    grid-template-columns: 10px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 10px;
    row-gap: 10px;
    column-gap: 5px;
  }

  .contact_sectionHeader_left {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 1;
    grid-row-end: 2;
    padding-top: 10px;
    gap: 5px;
  }

  .contact_sectionHeader_right {
    grid-column-start: 2;
    grid-column-end: 14;
    grid-row-start: 2;
    grid-row-end: 3;
    padding-top: 10px;
    gap: 15px;
  }
}
