/* Wrapper to center the navbar */
.HomeFeature_container {
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgb(255, 255, 255);
}

.HomeFeature_Home {
  margin-top: 80px;
  margin-bottom: 0px;
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 2fr; /* 14 grid columns */
  /* align-items: center; */
  row-gap: 20px;
  column-gap: 30px;
  max-width: 2300px;
  background: #ffffff; 
}

.HomeFeature_BG{
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 1;
  grid-row-end: 4;
  background-color: #004D95;
}

.HomeFeatureBGimage{
  /* padding-top: 100px; */
  width: 100%;
}

.HomeFeature_Left{
  /* padding-top: 30%; */
  grid-column-start: 2;
  grid-column-end: 7;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 20%; */
  gap: 15px;
}

.buttonDiv2{
  margin-top: 15px;
}

.know-more-button3 {
  background-color: #1E9500; /* Fluent Design System blue */
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.HomeFeature_Right{
  grid-column-start: 8;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
  /* padding-top: 20%; */
  /* padding-bottom: 20%; */
}

/* Media Queries for Responsive Design */



/* For very small devices like phones in portrait mode */
@media (max-width: 480px) {
  .HomeFeature_Home {
    margin-top: 40px;
    margin-bottom: 0px;
    display: grid;
    width: 100%;
    grid-template-columns: 10px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 10px; /* 14 grid columns */
    /* align-items: center; */
    row-gap: 10px;
    column-gap: 5px;
    max-width: 2300px;
    background: #ffffff; 
  }

  
.HomeFeature_BG{
  grid-column-start: 1;
  grid-column-end: 15;
  grid-row-start: 1;
  grid-row-end: 8;
  background-color: #004D95;
}

.HomeFeatureBGimage{
  /* padding-top: 100px; */
  width: 100%;
}

.HomeFeature_Left{
  /* padding-top: 30%; */
  grid-column-start: 2;
  grid-column-end: 14;
  grid-row-start: 4;
  grid-row-end: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-bottom: 20%; */
  gap: 15px;
}

.buttonDiv2{
  margin-top: 15px;
}

.know-more-button3 {
  background-color: #1E9500; /* Fluent Design System blue */
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.HomeFeature_Right{
  grid-column-start: 3;
  grid-column-end: 13;
  grid-row-start: 2;
  grid-row-end: 3;
  width: 100%;
  padding-top: 10px;
  /* padding-bottom: 20%; */
}

}